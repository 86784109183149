
.site-footer--simple {
  @extend %footer-text-color;
  background-color: color(venice);
  @include type(16, 26);
  padding: .5rem 1rem;

  a,
  button {
    color: color(white);
    text-decoration: none;
    opacity: 0.8;
    display: block;

    &:hover,
    &:active {
      background-color: rgba(255, 255, 255, 0.2);
      opacity: 1;

      @include media($width-l) {
        background: none;
        opacity: 1;
        text-decoration: underline; } } } }

.site-footer--simple__links {
  text-align: center;

  > * {
    display: block;
    padding: .5rem 0; }

  @include media($width-l) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    > * {
      margin-right: 1rem; }
    > :last-child {
      margin-right: 0;
      margin-left: auto; } } }
